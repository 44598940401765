import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CreatorLogin from "./screens/creator/login/CreatorLogin";
import CreatorSignup from "./screens/creator/login/CreatorSignup";
import TripHome from "./screens/creator/triphome/TripHome";
// import Home from "./screens/Home";
import CreatorHome from "./screens/creator/home/CreatorHome";
import PrivateRoutes from "./ProtectedRoutes";
import RedirectionRoutes from "./RedirectionRoutes";

import "./App.css";
import Checkout from "./screens/Checkout";
import HomeCreator from "./screens/home_creator/HomeCreator";
import Confirmation from "./screens/Confirmation";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomeCreator />} />
        <Route element={<RedirectionRoutes />}>
          <Route path="creator/login" element={<CreatorLogin />} />
          <Route path="creator/signup" element={<CreatorSignup />} />
        </Route>
        <Route path="prabir/:slug" element={<TripHome />} />
        <Route path="prabir/:slug/checkout" element={<Checkout />} />
        <Route
          path="prabir/:slug/checkout/confirmation"
          element={<Confirmation />}
        />
        <Route element={<PrivateRoutes />}>
          <Route path="creator/home" element={<CreatorHome />} />
        </Route>
        <Route
          path="*"
          element={
            <Navigate to="/prabir/Stargazing-at-Kashmir-Great-Lakes" replace />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
